// Import Google fonts
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Roboto:400,700");

// Import Bootstrap styling, minus the problematic print stylesheet
$enable-print-styles: false;
@import "~bootstrap/scss/bootstrap.scss";

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: #fafafa;
  font-family: "Roboto", "Helvetica Neue", arial, sans-serif;
  font-weight: 400;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

#app {
  height: 100%;
}

.card {
  margin-bottom: 1.5rem;
  .card-header {
    font-size: 1.4rem;
    font-weight: 600;
  }
}
